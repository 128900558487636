
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import { Tabs } from '../../../utilities/Types/navigation.types';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    await this.resetPaginationState();

    const customUrl = new Map<string, string>();
    const url = window.location.href;
    let requestId: string | null = null;

    if (new URL(url).pathname.includes('/cm/all/')) {
      const urlToken = new URL(url).pathname.split('/').pop();
      if (urlToken) {
        requestId = urlToken;
      }
    }

    if (requestId) {
      this.requestId = requestId;
      const urlInformation = await this.fetchCommunicationMallsInformationViaPublicId(requestId);

      if (!urlInformation.valid) {
        this.loading = false;
        return;
      }

      const queryParameters = urlInformation.data.queryParams;

      for (const query of queryParameters.replace('?', '').split('&')) {
        const [key, value] = query.split('=');
        customUrl.set(key, value);
      }

      this.contactGroupId = customUrl.get('contactGroup') as string;
    } else {
      this.contactGroupId = this.$route.query.contactGroup as string;
    }
    const marketingCampaignsResponse = await this.fetchMallMarketingCampaigns(this.contactGroupId);
    this.marketingCampaignsTotal = marketingCampaignsResponse?.total;
    const recruitmentCampaignsResponse = await this.fetchMallRecruitmentCampaigns(this.contactGroupId);
    this.recruitmentCampaignsTotal = recruitmentCampaignsResponse?.total;
    const mallMemosResponse = await this.fetchMallMallMemos(this.contactGroupId);
    this.mallMemosTotal = mallMemosResponse?.total;
    this.total = this.marketingCampaignsTotal + this.recruitmentCampaignsTotal + this.mallMemosTotal;
  },
  watch: {
    pageValue: {
      deep: true,
      async handler() {
        await this.updatePaginationPage(this.pageValue.value as number);
        if (!this.pageReset) {
          const marketingCampaignsResponse = await this.fetchMallMarketingCampaigns(this.contactGroupId);
          this.marketingCampaignsTotal = marketingCampaignsResponse?.total;
          const recruitmentCampaignsResponse = await this.fetchMallRecruitmentCampaigns(this.contactGroupId);
          this.recruitmentCampaignsTotal = recruitmentCampaignsResponse?.total;
          const mallMemosResponse = await this.fetchMallMallMemos(this.contactGroupId);
          this.mallMemosTotal = mallMemosResponse?.total;
          this.total = this.marketingCampaignsTotal + this.recruitmentCampaignsTotal + this.mallMemosTotal;
        }
        this.pageReset = false;
      },
    },
    showValue: {
      deep: true,
      async handler() {
        await this.updatePaginationLimit(this.showValue.value as number);
        const marketingCampaignsResponse = await this.fetchMallMarketingCampaigns(this.contactGroupId);
        this.marketingCampaignsTotal = marketingCampaignsResponse?.total;
        const recruitmentCampaignsResponse = await this.fetchMallRecruitmentCampaigns(this.contactGroupId);
        this.recruitmentCampaignsTotal = recruitmentCampaignsResponse?.total;
        const mallMemosResponse = await this.fetchMallMallMemos(this.contactGroupId);
        this.mallMemosTotal = mallMemosResponse?.total;
        this.total = this.marketingCampaignsTotal + this.recruitmentCampaignsTotal + this.mallMemosTotal;
      },
    },
    searchFilter: {
      deep: true,
      async handler() {
        await this.updatePaginationSearch(this.searchFilter);
        const marketingCampaignsResponse = await this.fetchMallMarketingCampaigns(this.contactGroupId);
        this.marketingCampaignsTotal = marketingCampaignsResponse?.total;
        const recruitmentCampaignsResponse = await this.fetchMallRecruitmentCampaigns(this.contactGroupId);
        this.recruitmentCampaignsTotal = recruitmentCampaignsResponse?.total;
        const mallMemosResponse = await this.fetchMallMallMemos(this.contactGroupId);
        this.mallMemosTotal = mallMemosResponse?.total;
        this.total = this.marketingCampaignsTotal + this.recruitmentCampaignsTotal + this.mallMemosTotal;
      },
    },
    sortDefinition: {
      deep: true,
      async handler() {
        await this.updatePaginationSortKey(this.sortDefinition.column);
        await this.updatePaginationSortDirection(this.sortDefinition.direction);
        const marketingCampaignsResponse = await this.fetchMallMarketingCampaigns(this.contactGroupId);
        this.marketingCampaignsTotal = marketingCampaignsResponse?.total;
        const recruitmentCampaignsResponse = await this.fetchMallRecruitmentCampaigns(this.contactGroupId);
        this.recruitmentCampaignsTotal = recruitmentCampaignsResponse?.total;
        const mallMemosResponse = await this.fetchMallMallMemos(this.contactGroupId);
        this.mallMemosTotal = mallMemosResponse?.total;
        this.total = this.marketingCampaignsTotal + this.recruitmentCampaignsTotal + this.mallMemosTotal;
      },
    },
  },
  computed: {
    ...mapGetters([
      'communications',
      'marketingCampaigns',
      'recruitmentCampaigns',
      'mallMemos',
      'loadingMarketingCampaigns',
      'loadingRecruitmentCampaigns',
      'loadingMallMemos',
      'access',
      'permissions',
      'allCommunications',
    ]),
    isLoading(): boolean {
      return this.loading || this.loadingMarketingCampaigns || this.loadingRecruitmentCampaigns || this.loadingMallMemos;
    },
    showSearch(): boolean {
      return this.permissions && this.permissions?.superAdminAccess;
    },
    columnDefinitions(): ColumnDefinition[] {
      return [
        {
          type: 'text',
          columnName: 'secondaryStatusText',
          displayName: 'Action Needed',
          cellStyle: 'font-weight: bold; text-transform: uppercase;',
        },
        {
          type: 'review',
          columnName: 'reviewPageUrl',
          displayName: 'Review Page Url',
        },
        {
          type: 'text',
          columnName: 'createdByUserName',
          displayName: 'Created By',
        },
        {
          type: 'date-time-localized',
          columnName: 'submittedAt',
          displayName: 'Created At',
        },
        // todo
        // {
        //   type: 'text',
        //   columnName: 'campaignNumber',
        //   displayName: 'Campaign ID',
        //   sortable: true,
        // },
        {
          type: 'text',
          columnName: 'commType',
          displayName: 'Type',
        },
        {
          type: 'text',
          columnName: 'commSubTypeText',
          displayName: 'Type',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'brandText',
          displayName: 'Brand',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'statusText',
          displayName: 'Status',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'parsedHeadline',
          displayName: 'Headline',
        },
        {
          type: 'image',
          columnName: 'imageUrl',
          displayName: 'Image',
          position: 'center',
        },
        // todo
        // {
        //   type: 'date',
        //   columnName: 'publishByDate',
        //   displayName: 'Post No Earlier Than Date',
        // },
        {
          type: 'date',
          columnName: 'fromDate',
          displayName: 'Start Date',
          sortable: true,
        },
        {
          type: 'date',
          columnName: 'toDate',
          displayName: 'End Date',
          sortable: true,
        },
        {
          type: 'approval',
          columnName: 'stats',
          displayName: 'Approval',
          headerStyle: 'min-width: 525px',
        },
      ];
    },
    tableData(): unknown {
      this.startLoading();
      let data: any[] = [];
      if (!this.isLoading && !this.allCommunications) {
        if (this.marketingCampaigns?.length > 0) {
          data = [...data, ...this.marketingCampaigns];
        }
        if (this.recruitmentCampaigns?.length > 0) {
          data = [...data, ...this.recruitmentCampaigns];
        }
        if (this.mallMemos?.length > 0) {
          data = [...data, ...this.mallMemos];
        }
        this.updateAllCommunications(data);
      }
      if (this.allCommunications) {
        data = this.allCommunications;
      }
      this.finishLoading();
      return data;
    },
    tableOptions(): any {
      return {
        clickable: true,
      };
    },
    tabs(): Tabs {
      return [
        {
          title: 'ALL',
          route: `/cm/all/${this.requestId}`,
          activeRouteCheck: '/cm/all',
        },
        {
          title: 'MARKETING CAMPAIGNS',
          route: `/cm/mc/${this.requestId}`,
          activeRouteCheck: '/cm/mc',
        },
        {
          title: 'RECRUITMENT CAMPAIGNS',
          route: `/cm/rc/${this.requestId}`,
          activeRouteCheck: '/cm/rc',
        },
        {
          title: 'MALL MEMOS',
          route: `/cm/mm/${this.requestId}`,
          activeRouteCheck: '/cm/mm',
        },
      ];
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = 100;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = 100;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    statusFilterValue: IDropdownOption;
    statusFilterOptions: IDropdownOption[];
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    pageReset: boolean;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    marketingCampaignsTotal: number;
    recruitmentCampaignsTotal: number;
    mallMemosTotal: number;
    total: number;
    requestId: string;
    contactGroupId: string;
  } {
    return {
      statusFilterValue: { description: 'All', value: 'all' },
      statusFilterOptions: [
        { description: 'All', value: 'all' },
        { description: 'Active', value: 'active' },
        { description: 'Upcoming', value: 'upcoming' },
        { description: 'Expired', value: 'expired' },
        { description: 'Draft', value: 'draft' },
      ],
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      pageReset: false,
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      marketingCampaignsTotal: 0,
      recruitmentCampaignsTotal: 0,
      mallMemosTotal: 0,
      total: 0,
      requestId: '',
      contactGroupId: '',
    };
  },

  methods: {
    ...mapActions([
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'fetchMallMarketingCampaigns',
      'fetchMallRecruitmentCampaigns',
      'fetchMallMallMemos',
      'clearSelectedRecruitmentCampaign',
      'clearSelectedMarketingCampaign',
      'clearSelectedMallMemo',
      'updateAllCommunications',
      'updatePaginationPage',
      'updatePaginationLimit',
      'updatePaginationSearch',
      'updatePaginationSortKey',
      'updatePaginationSortDirection',
      'resetPaginationState',
      'fetchCommunicationMallsInformationViaPublicId',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateStatusFilter(filter: IDropdownOption) {
      this.resetPageValue();
      this.statusFilterValue = filter;
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    async search(searchFilter: string): Promise<void> {
      this.resetPageValue();
      this.searchFilter = searchFilter;
    },
    // create(): void {
    //   let type = 'system-admin';
    //   this.clearSelectedRecruitmentCampaign();
    //   this.clearSelectedMarketingCampaign();
    //   this.clearSelectedMallMemo();
    //   this.updateModalData(null);
    //   this.updateModalComponent('select');
    //   this.updateModalType(type);
    //   this.openModal();
    // },
    // reports(): void {
    //   this.$router.push('/reports/all');
    // },
    // export(): void {
    //   alert('to-do: Export table data');
    // },
    rowClicked(row: any): void {
      if (row.reviewPageUrl) {
        window.open(row.reviewPageUrl, '_blank');
      }
    },
    approvalClicked(payload: { status: string; type: string; row: any }): void {
      // do nothing
    },
  },
});
